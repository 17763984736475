@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 16px;
}

input:focus {
  outline: none;
}

.flip-vertical-fwd {
  -webkit-animation: flip-vertical-fwd 0.4s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@-webkit-keyframes flip-vertical-fwd {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
  }
}
@keyframes flip-vertical-fwd {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
  }
}
.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  cursor: pointer;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 5px;
  border-radius: 8px;
  overflow: hidden;
}

.dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  color: #000000;
}

.dropdown-item:hover {
  background-color: #ddd;
}

.content ul,
.content ol {
  list-style-type: disc;
  margin: 1em 0;
  padding-left: 1.5em;
}

.content li {
  line-height: 1.5;
  margin-bottom: 0.5em;
}

.ql-size-small {
  font-size: 8px; 
}

.ql-size-normal {
  font-size: 16px; 
}

.ql-size-large {
  font-size: 24px; 
}

.ql-size-huge {
  font-size: 32px; 
}